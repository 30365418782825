import { compact, each, map, orderBy, remove, uniq } from 'lodash';
import CountryState from 'vue-app/shared/services/country-state.js';
import LoadingService from 'vue-app/shared/services/loading-service.js';
import Proficiency from 'resources/proficiency.js';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';

class RfpDataService {
  constructor() {
    this.sharedLists = SharedListsService;
    this.countryState = new CountryState();
    this.lists = {
      yearsExperience: this.sharedLists.yearsExperience,
      countries: this.sharedLists.listOfCountries(),
      barCountries: map(this.countryState.countries, (c) => { return { country: c, selected: false }; }),
      states: this.sharedLists.listOfStateObjects(),
      barStates: each(this.sharedLists.listOfStateObjects(), (s) => { s.selected = false; }),
      projectTypeOptions: [
        { slug: 'advisory', label: 'Project' },
        { slug: 'overflow', label: 'People' }
      ],
      isOnsiteOptions: [
        { slug: 'yes', label: 'On-Site' },
        { slug: 'no', label: 'Remote (note: widens the pool of candidates)' }
      ],
      experienceOptions: this.sharedLists.rfpExperienceOptions,
      feeStructureOptions: [
        { slug: 'per_hour', label: 'Hourly Rate' },
        { slug: 'per_week', label: 'Weekly Rate' },
        { slug: 'per_month', label: 'Monthly Rate' },
        { slug: 'per_project', label: 'Flat Fee' },
        { slug: 'other', label: 'Other' }
      ],
      feeStructureDisplayOptions: [
        { slug: 'per_hour', label: 'Per Hour' },
        { slug: 'per_week', label: 'Per Week' },
        { slug: 'per_month', label: 'Per Month' }
      ],
      accreditationOptions: this.sharedLists.rfpAccreditationOptions,
      practiceAreas: []
    };

    this.fetchProficiency();
  }

  fetchProficiency() {
    LoadingService.loading('rfpProficiency');
    Proficiency.query().then((proficiencies) => {
      each(uniq(map(proficiencies, 'practiceArea.name')), (practiceArea) => {
        proficiencies.push({ name: practiceArea, practiceArea: { name: practiceArea } });
      });

      this.lists.practiceAreas = orderBy(proficiencies, ['practiceArea.name', 'name']);
      LoadingService.done('rfpProficiency');
    });
  }

  processRfpForSubmit(request, isEditing) {
    if (isEditing) {
      if (request.professionalType !== 'Lawyer') {
        request.requiredBars = [];
      }
      else {
        remove(request.requiredBars, (bar) => {
          return !bar.country;
        });
      }

      if (request.professionalType === 'Law Firm') {
        remove(request.lawFirmOfficeLocations, (location) => {
          if (location.city === null) { delete location.city; }
          if ((location.country === 'United States' && location.state === null) || location.country !== 'United States') {
            delete location.state;
          }

          return !location.country;
        });
      }
      else {
        request.lawFirmOfficeLocations = [];
      }

      if (request.projectType === 'advisory') {
        request.isOngoing = false;

        request.duration = null;
        request.startDate = null;
        request.startDateType = null;
        request.timeCommitment = null;
        request.timeCommitmentDetails = null;
        request.isOnsite = null;
        request.onsiteDetails = null;
      }
      else {
        if (request.isOngoing || !(request.duration || '').length) {
          request.duration = null;
        }

        if (request.startDateType === 'asap' || !request.startDateType) {
          request.startDate = null;
        }

        if (!(request.timeCommitmentDetails || '').length) {
          request.timeCommitmentDetails = null;
        }

        if (request.isOnsite !== 'yes' || !(request.onsiteDetails || '').length) {
          request.onsiteDetails = null;
          request.onsiteLocation = null;
        }
      }
    }
    else {
      if (request.professionalType !== 'Lawyer') {
        request.requiredBars = [];
      }
      else {
        remove(request.requiredBars, (bar) => {
          return !bar.country;
        });

        each(request.requiredBars, (bar) => {
          if (bar.country !== 'United States' || (bar.country === 'United States' && !bar.state)) { delete bar.state; }
        });
      }

      if (request.professionalType === 'Law Firm') {
        remove(request.lawFirmOfficeLocations, (location) => {
          if (location.city === null) { delete location.city; }
          if ((location.country === 'United States' && location.state === null) || location.country !== 'United States') {
            delete location.state;
          }

          return !location.country;
        });
      }
      else {
        request.lawFirmOfficeLocations = [];
      }

      if (!request.state) { delete request.state; }
      if (!request.country) { delete request.country; }
      if (!request.experienceYears) { delete request.experienceYears; }

      if (request.projectType === 'advisory') {
        request.isOngoing = false;

        delete request.duration;
        delete request.startDate;
        delete request.startDateType;
        delete request.timeCommitment;
        delete request.timeCommitmentDetails;
        delete request.isOnsite;
        delete request.onsiteDetails;
      }
      else {
        if (request.isOngoing || !(request.durationNumberLower || request.durationNumberUpper) || !request.durationUnit) {
          delete request.duration;
        }
        else {
          let durationUnit = request.durationUnit.toLowerCase();
          if (!(request.durationNumberLower && request.durationNumberUpper)) {
            if (request.durationNumberLower === 1 || request.durationNumberUpper === 1) {
              durationUnit = durationUnit.slice(0, -1);
            }
          }

          const durationRange = compact([request.durationNumberLower, request.durationNumberUpper]).join('-');
          request.duration = durationRange + ' ' + durationUnit;
        }

        if (!request.startDateType) { delete request.startDateType; }
        if (request.startDateType === 'asap' || !request.startDateType) {
          delete request.startDate;
        }

        if (!request.timeCommitment) { delete request.timeCommitment; }
        if (!(request.timeCommitmentDetailsNumberLower || request.timeCommitmentDetailsNumberUpper) || !request.timeCommitmentDetailsUnit) {
          delete request.timeCommitmentDetails;
        }
        else if (request.timeCommitment === 'Part-time' && (request.timeCommitmentDetailsNumberLower || request.timeCommitmentDetailsNumberUpper) && request.timeCommitmentDetailsUnit) {
          let timeUnit = request.timeCommitmentDetailsUnit.toLowerCase();

          if (!(request.timeCommitmentDetailsNumberLower && request.timeCommitmentDetailsNumberUpper)) {
            if (request.timeCommitmentDetailsNumberLower === 1 || request.timeCommitmentDetailsNumberUpper === 1) {
              timeUnit = timeUnit.slice(0, -1);
            }
          }

          const timeRange = compact([request.timeCommitmentDetailsNumberLower, request.timeCommitmentDetailsNumberUpper]).join('-');

          request.timeCommitmentDetails = timeRange + ' ' + timeUnit + ' per week';
        }

        if (!request.isOnsite) { delete request.isOnsite; }
        if (request.isOnsite !== 'yes' || !request.onsiteDetailsUnit || !(request.onsiteDetailsNumberLower || request.onsiteDetailsNumberUpper)) {
          delete request.onsiteDetails;
          delete request.onsiteLocation;
        }
        else if (request.onsiteDetailsUnit && (request.onsiteDetailsNumberLower || request.onsiteDetailsNumberUpper)) {
          let onsiteUnit = request.onsiteDetailsUnit.toLowerCase();

          if (!(request.onsiteDetailsNumberLower && request.onsiteDetailsNumberUpper)) {
            if (request.onsiteDetailsNumberLower === 1 || request.onsiteDetailsNumberUpper === 1) {
              onsiteUnit = onsiteUnit.slice(0, -1);
            }
          }

          const onsiteRange = compact([request.onsiteDetailsNumberLower, request.onsiteDetailsNumberUpper]).join('-');
          request.onsiteDetails = onsiteRange + ' ' + onsiteUnit + ' per week';
        }
      }
    }

    if (request.budgetFeeStructure) {
      if (request.budgetFeeStructure === 'per_project') {
        request.feeStructure = 'fixed';
        request.feeInterval = request.budgetFeeStructure;
      }
      else if (request.budgetFeeStructure !== 'other') {
        request.feeStructure = 'rated';
        request.feeInterval = request.budgetFeeStructure;
      }
      else {
        request.feeStructure = 'other';
      }

      if (request.budgetFeeStructure !== 'other') {
        delete request.feeStructureDescription;
      }
    }

    if (!request.budgetRangeUpper) { request.budgetRangeUpper = '0'; }
    if (!request.budgetRangeLower) { request.budgetRangeLower = '0'; }
    if (!request.budgetDescription) { delete request.budgetDescription; }

    if (request.document) {
      request.documentsAttributes = [{ file: request.document }];
      if (request.documents && request.documents[0]) {
        request.documentsAttributes.push({ id: request.documents[0].id, _destroy: true });
      }
      delete request.document;
    }
  }
}

export default RfpDataService;
