import { concat, sortBy } from 'lodash';
import SharedListsService from 'vue-app/shared/services/shared-lists-service.js';

class CountryState {
  constructor() {
    this.sharedLists = SharedListsService;
    this.countries = concat(this.sharedLists.listOfCountries(), [
      'United Kingdom (Scotland)',
      'United Kingdom (England and Wales)',
      'United Kingdom (Northern Ireland)'
    ]);
    this.countries = sortBy(this.countries);
  }

  isCountryWithStates(country) {
    return country === 'United States';
  }

  statesByCountry(country) {
    if (country === 'United States') {
      return this.sharedLists.listOfStateObjects();
    }
    else {
      return [];
    }
  }
}

export default CountryState;
