<template>
  <div class="uiv-datepicker">
    <date-picker
      @input="setDate"
      v-model="clonedDate"
      :clear-btn="clearButton"
      :format="format"
      :limit-from="limitFrom"
      :today-btn="todayButton"
      :width="width"
      :year-month-formatter="dateYearMonthFormat">
    </date-picker>
  </div>
</template>

<script>
export default {
  name: 'StyledDatePicker',

  props: {
    date: {
      type: String,
      default: null
    },

    clearButton: {
      type: Boolean,
      default: false
    },

    limitFrom: {
      type: [Date, String],
      default: null
    },

    format: {
      type: String,
      default: 'MM/dd/yyyy'
    },

    todayButton: {
      type: Boolean,
      default: false
    },

    width: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      clonedDate: this.date
    };
  },

  methods: {
    setDate(date) {
      this.clonedDate = date;
      this.$emit('input', date);
    },

    dateYearMonthFormat(year, month) {
      month = new Date(year, month).toLocaleString('default', { month: 'long' });
      return `${month.toUpperCase()} ${year}`;
    }
  }
};
</script>

<style lang="scss" scoped>
  @import "stylesheets/globals/variables";
  @import "stylesheets/globals/mixins";

  :deep(.uiv-datepicker) {
    @include k-shadow;
    width: 100%;
    border-radius: $border-radius-base;

    .btn-sm {
      border-radius: $border-radius-base;
    }

    .btn-default {
      background-color: $white;
    }

    .btn.disabled {
      &:hover {
        color: $k-darker-gray;
        font-weight: 400;
      }
    }

    .btn-info {
      background-color: $white;
      color: $k-blue;
      font-weight: 700;
    }

    .btn-primary {
      background-color: $k-blue;
      color: $white;
      font-weight: 700;

      .text-muted {
        color: $white;
      }
    }

    thead tr:last-of-type td {
      background-color: $white;
      padding: 7px 10px;
    }

    table {
      background-color: $white;
      border-radius: $border-radius-base;
    }

    tbody {
      td {
        background-color: $white;
        border-radius: $border-radius-base;
      }

      button {
        padding: 7px 5px;

        &:active, &:focus {
          outline: none;
        }
      }

      .btn:active {
        box-shadow: none;
      }

      .btn-default:hover {
        color: $k-blue;
        font-weight: 700;
      }

      .btn-default:active, .btn-default:focus {
        background-color: $white;
      }

      .btn-info:active, .btn-info:focus {
        color: $k-blue;
        background-color: $white;
      }

      .btn-primary:active, .btn-primary:focus {
        background-color: $k-blue;
      }
    }

    .uiv-datepicker-pager-prev {
      margin: 0;
      background-color: $k-blue;
      border-radius: $border-radius-base 0 0 0;
      color: $white;
      height: 50px;

      &:active, &:focus {
        color: $white;
        background-color: $k-blue;
        outline: none;
      }
    }

    .uiv-datepicker-title {
      margin: 0;
      background-color: $k-blue;
      border-radius: 0;
      color: $white;
      height: 50px;
      font-weight: 400;

      b {
        font-weight: 600;
      }

      &:active, &:focus {
        color: $white;
        background-color: $k-blue;
        outline: none;
      }
    }

    .uiv-datepicker-pager-next {
      margin: 0;
      background-color: $k-blue;
      border-radius: 0 $border-radius-base 0 0;
      color: $white;
      height: 50px;

      &:active, &:focus {
        color: $white;
        background-color: $k-blue;
        outline: none;
      }
    }

    .uiv-datepicker-week {
      font-size: 12px;
      font-weight: 600;
    }
  }
</style>
