<template>
  <div>
    <modal v-model="modalOpen" size="md-sm" :transition="0" :header="false" :footer="false" @hide="$emit('on-modal-close')" append-to-body>
      <modal-close-button :on-dismiss="dismissModal"></modal-close-button>

      <div class="notice-modal">
        <div class="notice-header">
          Confirm
        </div>

        <div :class="{ 'notice-text': !showConfirmationText, 'notice-regular-text': showConfirmationText }">
          <div :class="{ 'size-text-base': showConfirmationText }">
            {{ message }}
          </div>
        </div>

        <div class="notice-note dark-gray-text" v-if="note">
          <span class="semibold-weight">Important Note: </span> {{ note }}
        </div>

        <div class="notice-note notice-note-red" v-if="redNote">
          <span class="semibold-weight">NOTE: </span> {{ redNote }}
        </div>

        <div class="notice-action-container">
          <div class="row">
            <div class="col-sm-6 bottom-10">
              <button type="button" class="nv-button-red a-button-size" @click="confirm" :disabled="disableYesButton">{{ yesBtnText }}</button>
            </div>

            <div class="col-sm-6 bottom-30">
              <button type="button" class="nv-button-white a-button-size" @click="dismiss">{{ noBtnText }}</button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ModalCloseButton from 'vue-app/shared/components/modals/modal-close-button.vue';
import modalToggle from 'vue-app/shared/mixins/modal-toggle';

export default {
  name: 'RequestConfirmation',

  components: {
    ModalCloseButton
  },

  mixins: [
    modalToggle
  ],

  props: {
    note: String,

    redNote: String,

    show: {
      type: Boolean,
      default: false
    },

    message: {
      type: String,
      default: 'Are you sure?'
    },

    yesBtnText: {
      type: String,
      default: 'Yes'
    },

    noBtnText: {
      type: String,
      default: 'No'
    },

    showConfirmationText: {
      type: Boolean,
      default: false
    },

    confirmationText: {
      type: String,
      default: 'CONFIRM'
    }
  },

  computed: {
    disableYesButton() {
      return this.showConfirmationText && this.confirmText !== this.confirmationText;
    }
  },

  watch: {
    show(newValue, _oldValue) {
      this.modalOpen = newValue;
    }
  },

  methods: {
    confirm() {
      this.modalOpen = false;
      this.$emit('on-modal-yes');
    },

    dismiss() {
      this.modalOpen = false;
      this.$emit('on-modal-no');
      this.dismissModal();
    }
  }
};
</script>
